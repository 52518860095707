/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';

const VideoModal = ({ videoUrl, setVideoUrl }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (videoUrl) {
      return setVisible(true);
    }
    return setVisible(false);
  }, [videoUrl]);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      setVisible={() => {
        setVideoUrl('');
      }}
      isVideoModal
      className="video-modal-main-container"
    >
      <iframe
        title="video"
        allow="autoplay; fullscreen"
        src={videoUrl}
        scrolling="no"
        style={{ width: '100%', height: '100%' }}
      />
    </Modal>
  );
};

VideoModal.propTypes = {
  videoUrl: PropTypes.string,
  setVideoUrl: PropTypes.func,
};

export default VideoModal;
